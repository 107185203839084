<template>
  <b-container fluid class="dashboard-page">
    <b-row>
      <b-col lg="7" xl="8" cols="12">
        <b-row>
          <b-col lg="4" sm="6" cols="12">
            <BoxElement
              title="Balance"
              :value="`$ ${TruncateToDecimals(InfoDashboard.MainBalance, 4)}`"
              class="balance-card"
            ></BoxElement>
          </b-col>
          <b-col lg="4" sm="6" cols="12">
            <BoxElement
              title="Active Bot Commission"
              :value="`$ ${TruncateToDecimals(InfoDashboard.ActiveBotCommis, 4)}`"
              class="balance-card"
            ></BoxElement>
          </b-col>
          <b-col lg="4" sm="6" cols="12">
            <BoxElement
              title="Profit commission"
              :value="`$ ${TruncateToDecimals(InfoDashboard.ProfitCommis, 4)}`"
              class="balance-card"
            ></BoxElement>
          </b-col>
          <b-col lg="4" sm="6" cols="12">
            <BoxElement
              title="Binance Balance"
              :value="`$ ${TruncateToDecimals(InfoDashboard.BinanceBalance, 4)}`"
              class="balance-card"
            ></BoxElement>
          </b-col>
          <b-col lg="4" sm="6" cols="12">
            <BoxElement
              title="Total Profit"
              :value="`$ ${TruncateToDecimals(InfoDashboard.TotalProfit, 4)}`"
              class="balance-card"
            ></BoxElement>
          </b-col>
          <b-col lg="4" sm="6" cols="12">
            <BoxElement
              title="Today Profit"
              :value="`$ ${TruncateToDecimals(InfoDashboard.TodayProfit, 4)}`"
              class="balance-card"
            ></BoxElement>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" cols="12">
            <div class="element-chart">
              <div class="chart">
                <TradingViewChart />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="5" xl="4" cols="12" class="mt-4">
        <b-button variant="info" class="button-active" @click="onActiveBot">
          {{
            DetailBot && DetailBot.info_bot && DetailBot.info_bot.bot_active == 1
              ? 'Bot Is Actived'
              : 'Active Bot Now'
          }}
        </b-button>
        <b-button
          variant="info"
          class="button-active"
          @click="onShowOffBot"
          v-if="DetailBot && DetailBot.info_bot && DetailBot.info_bot.bot_active == 1"
        >
          {{
            DetailBot && DetailBot.info_bot && DetailBot.info_bot.bot_status == 0
              ? ' Turn On Bot'
              : 'Turn Off Bot'
          }}
        </b-button>
        <div class="box-setting-bot">
          <div class="box-header d-flex justify-content-center align-items-center">
            <h3>Setting bot</h3>
          </div>
          <div class="box-type">
            <b-button :class="botType === 1 ? 'button-tab-active' : ''" @click="botType = 1"
              >Automatic</b-button
            >
            <b-button :class="botType === 2 ? 'button-tab-active' : ''" @click="botType = 2"
              >Advanced</b-button
            >
          </div>
          <div class="box-body">
            <!-- Form save bot -->
            <b-form @submit.prevent="onSaveInfoBot" v-if="DetailBot.checkbot == 0">
              <b-row>
                <!-- Symbol -->
                <b-col md="12" cols="12" v-if="botType == 2">
                  <b-form-group label="Trading Currency Pairs">
                    <!-- <multiselect
                      v-model="saveBot.bot_trading_symbol"
                      :multiple="true"
                      :clear-on-select="true"
                      :options="ListTradingSymbol"
                      label="trading_symbol_name"
                      trackBy="trading_symbol_id"
                    >
                    </multiselect> -->
                    <v-select
                      class="style-chooser"
                      :options="ListTradingSymbol"
                      :reduce="(symbol) => symbol.trading_symbol_id"
                      v-model="saveBot.bot_trading_symbol"
                      multiple
                      label="trading_symbol_name"
                      :clearable="false"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <!-- Pairs Group -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Pairs Group">
                    <b-form-select
                      v-model="saveBot.bot_strategy"
                      :options="arrGroup"
                      class="btn text-left"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- Stratery -->
                <b-col md="6" cols="12" v-if="botType == 2">
                  <b-form-group label="Stratery">
                    <b-form-select
                      v-model="saveBot.bot_strategy"
                      :options="ListStratege"
                      class="btn btn-info text-left"
                      value-field="strategy_id"
                      text-field="strategy_name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- Expand Order -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Expand Order (%)">
                    <b-form-input
                      v-model="saveBot.bot_expand_order"
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Fist-order Amount -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Fist-order Amount ($)">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="saveBot.bot_first_order_amount"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Investment -->
                <b-col md="6" cols="12">
                  <b-form-group label="Investment">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter Amount"
                      required
                      v-model="saveBot.bot_investment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Profit Target ($) -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Profit Target ($)">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="saveBot.bot_target_profit"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Rate Of Next Order -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Rate Of Next Order (%)">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="saveBot.bot_rate_next_order"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Trailing Stop -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Trailing Stop">
                    <div class="d-flex">
                      <input
                        type="checkbox"
                        class="checkbox-group"
                        v-model="selectedTrailingStop"
                      />
                      <b-form-input
                        id="input-1"
                        type="number"
                        placeholder="Enter number"
                        required
                        v-model="saveBot.bot_trailing_stop"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Litmit/Symbal -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Litmit/Symbal">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="saveBot.bot_limit_symbol"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Arlet when matching order -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group>
                    <b-form-checkbox
                      class="checkbox-group ml-lg d-flex align-items-center"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                      v-model="saveBot.bot_order_matching_report"
                    >
                      Arlet when matching order
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <!-- Optimal profit -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group>
                    <b-form-checkbox
                      class="checkbox-group ml-lg d-flex align-items-center"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                      v-model="saveBot.bot_optimal_profit"
                    >
                      Optimal profit
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12" cols="12">
                  <b-form-group class="mx-auto text-center">
                    <b-button variant="animation-info min-width-160 mt-2" type="submit">
                      SAVE
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <!-- Form update bot -->
            <b-form @submit.prevent="onUpdateInfoBot" v-if="DetailBot.checkbot == 1">
              <b-row>
                <!-- Symbol -->
                <b-col md="12" cols="12" v-if="botType == 2">
                  <!-- :selectable="() => employerUpdate.company_career.length < 3" -->
                  <b-form-group label="Trading Currency Pairs">
                    <v-select
                      class="style-chooser"
                      :options="ListTradingSymbol"
                      :reduce="(symbol) => symbol.trading_symbol_id"
                      v-model="saveBot.bot_trading_symbol"
                      multiple
                      label="trading_symbol_name"
                      :clearable="false"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <!-- Pairs Group -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Pairs Group">
                    <b-form-select
                      v-model="DetailBot.info_bot.bot_strategy"
                      :options="arrGroup"
                      class="btn btn-info text-left"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- Stratery -->
                <b-col md="6" cols="12" v-if="botType == 2">
                  <b-form-group label="Stratery">
                    <b-form-select
                      v-model="DetailBot.info_bot.bot_strategy"
                      :options="ListStratege"
                      class="btn btn-info text-left"
                      value-field="strategy_id"
                      text-field="strategy_name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- Expand Order -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Expand Order (%)">
                    <b-form-input
                      v-model="DetailBot.info_bot.bot_expand_order"
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Fist-order Amount -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Fist-order Amount ($)">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="DetailBot.info_bot.bot_first_order_amount"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Investment -->
                <b-col md="6" cols="12">
                  <b-form-group label="Investment">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter Amount"
                      required
                      v-model="DetailBot.info_bot.bot_investment"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Profit Target ($) -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Profit Target ($)">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="DetailBot.info_bot.bot_target_profit"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Rate Of Next Order -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Rate Of Next Order (%)">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="DetailBot.info_bot.bot_rate_next_order"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Trailing Stop -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Trailing Stop">
                    <div class="d-flex">
                      <input
                        type="checkbox"
                        class="checkbox-group"
                        v-model="selectedTrailingStop"
                      />
                      <b-form-input
                        id="input-1"
                        type="number"
                        placeholder="Enter number"
                        required
                        v-model="DetailBot.info_bot.bot_trailing_stop"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Litmit/Symbal -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group label="Litmit/Symbal">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter number"
                      required
                      v-model="DetailBot.info_bot.bot_limit_symbol"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <!-- Arlet when matching order -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group>
                    <b-form-checkbox
                      class="checkbox-group ml-lg d-flex align-items-center"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                      v-model="DetailBot.info_bot.bot_order_matching_report"
                    >
                      Arlet when matching order
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <!-- Optimal profit -->
                <b-col md="6" cols="12" v-if="false">
                  <b-form-group>
                    <b-form-checkbox
                      class="checkbox-group ml-lg d-flex align-items-center"
                      name="checkbox-1"
                      :value="1"
                      :unchecked-value="0"
                      v-model="DetailBot.info_bot.bot_optimal_profit"
                    >
                      Optimal profit
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="12" cols="12">
                  <b-form-group class="mx-auto text-center">
                    <b-button variant="animation-info min-width-160 mt-2" type="submit">
                      UPDATE
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BoxElement from '@/components/element/Box.vue';
import TradingViewChart from '@/components/chart/TradingView.vue';

export default {
  components: { BoxElement, TradingViewChart },
  data() {
    return {
      saveBot: {
        type: 0,
        bot_trading_symbol: '',
        bot_strategy: '',
        bot_investment: '',
        bot_limit_symbol: '',
        bot_expand_order: '',
        bot_first_order_amount: '',
        bot_target_profit: '',
        bot_rate_next_order: '',
        bot_trailing_stop: '',
        bot_optimal_profit: '',
        bot_order_matching_report: '',
      },
      selectedTrailingStop: false,
      selected2: null,
      value: null,
      optionsSelect: ['list', 'of', 'options'],
      options: [
        { value: null, text: 'BTCUSDT' },
        { value: 'a', text: 'BTCUSDT' },
        { value: 'b', text: 'BTCUSDT' },
        { value: { C: '3PO' }, text: 'BTCUSDT' },
        { value: 'd', text: 'BTCUSDT' },
      ],
      arrGroup: [
        { value: null, text: 'Please select an option' },
        { value: 1, text: 'Group 1' },
        { value: 1, text: 'Group 2' },
        { value: 1, text: 'Group 3' },
        { value: 1, text: 'Group 4' },
        { value: 1, text: 'Group 5' },
      ],
      checkBox: [{ value: null }],
      botType: 1,
      arrId_bot_trading_symbol: [],
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      InfoDashboard: 'wallet/InfoDashboard',
      ListTradingSymbol: 'bot/ListTradingSymbol',
      ListStratege: 'bot/ListStratege',
      DetailBot: 'bot/DetailBot',
    }),
    url() {
      return `${window.location.hostname}/secure/register?sponsor=${this.UserInfo.id}`;
    },
  },
  methods: {
    onCopy() {
      this.$toastr.s('Copy Link Referral Success', 'Successfully');
    },
    onError() {
      this.$toastr.e('Copy Link Referral Fail,Please Try Again', 'Failed');
    },
    onSaveInfoBot() {
      if (!this.selectedTrailingStop) {
        this.saveBot.bot_trailing_stop = '';
      }
      // const arrIdSymbol = [];
      // for (let i = 0; i < this.saveBot.bot_trading_symbol.length; i += 1) {
      //   arrIdSymbol.push(this.saveBot.bot_trading_symbol[i].trading_symbol_id);
      // }
      if (this.botType === 2) {
        this.saveBot.bot_trading_symbol = this.saveBot.bot_trading_symbol.toString();
      }
      this.saveBot.type = this.botType;
      this.$store.dispatch('bot/req_postSaveOrUpdateBot', this.saveBot);
      this.saveBot.bot_trading_symbol = [];
    },
    onUpdateInfoBot() {
      if (!this.selectedTrailingStop) {
        this.saveBot.bot_trailing_stop = '';
      }
      if (this.botType === 2) {
        this.saveBot = {
          type: this.botType,
          bot_trading_symbol: this.saveBot.bot_trading_symbol.toString(),
          bot_strategy: this.DetailBot.info_bot.bot_strategy,
          bot_investment: this.DetailBot.info_bot.bot_investment,
          bot_limit_symbol: this.DetailBot.info_bot.bot_limit_symbol,
          bot_expand_order: this.DetailBot.info_bot.bot_expand_order,
          bot_first_order_amount: this.DetailBot.info_bot.bot_first_order_amount,
          bot_target_profit: this.DetailBot.info_bot.bot_target_profit,
          bot_rate_next_order: this.DetailBot.info_bot.bot_rate_next_order,
          bot_trailing_stop: this.DetailBot.info_bot.bot_trailing_stop,
          bot_optimal_profit: this.DetailBot.info_bot.bot_optimal_profit,
          bot_order_matching_report: this.DetailBot.info_bot.bot_order_matching_report,
        };
      }
      if (this.botType === 1) {
        this.saveBot = {
          type: this.botType,
          bot_investment: this.DetailBot.info_bot.bot_investment,
        };
      }

      this.$store.dispatch('bot/req_postSaveOrUpdateBot', this.saveBot);
    },
    onActiveBot() {
      this.$store.dispatch('bot/req_postActiveBot');
    },
    onShowOffBot() {
      this.$store.dispatch('bot/req_postShowOffBot');
    },
    getBotTradingSymbol() {
      const arrResult = [];
      if (this.arrId_bot_trading_symbol.length > 0) {
        for (let i = 0; i < this.arrId_bot_trading_symbol.length; i += 1) {
          for (let j = 0; j < this.ListTradingSymbol.length; j += 1) {
            if (this.ListTradingSymbol[j].trading_symbol_id === this.arrId_bot_trading_symbol[i]) {
              arrResult.push(this.ListTradingSymbol[j].trading_symbol_id);
            }
          }
        }
      }
      this.saveBot.bot_trading_symbol = arrResult;
    },
  },
  watch: {
    DetailBot: {
      handler(newVal) {
        if (newVal.info_bot) {
          this.botType = newVal.info_bot.bot_type;
          if (newVal.info_bot.bot_trading_symbol) {
            const arr = [];
            const arrSymbolId = newVal.info_bot.bot_trading_symbol.split(',');
            for (let i = 0; i < arrSymbolId.length; i += 1) {
              // eslint-disable-next-line radix
              arr.push(parseInt(arrSymbolId[i]));
            }
            this.arrId_bot_trading_symbol = arr;
            this.getBotTradingSymbol();
          }
        }
      },
    },
    ListTradingSymbol: {
      handler(newVal) {
        if (newVal) {
          this.getBotTradingSymbol();
        }
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('bot/req_getDetailBot');
    await this.$store.dispatch('bot/req_getListTradingSymbol');
    await this.$store.dispatch('bot/req_getListStrategy');
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'core/ERROR_MESSAGE':
          this.getBotTradingSymbol();
          break;
        case 'bot/UPDATE_BOT_SUCCESS':
          this.$store.dispatch('bot/req_getDetailBot');
          this.$store.dispatch('bot/req_getListTradingSymbol');
          this.$store.dispatch('bot/req_getListStrategy');
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.v-select {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 5px;
  .vs__selected {
    background-color: #0c83e9;
    border-color: #0c83e9;
    color: #ffffff;
    .vs__deselect {
      fill: #000;
      svg {
        width: 9px;
        height: 9px;
      }
    }
  }
}
.btn-info {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
}
.btn-animation-info {
  color: #fff;
  background-color: #0c83e9;
  transition: 0.3s;
  border-color: #0c83e9;
  animation: glowing 2s linear infinite;
}
.btn-animation-info:hover {
  color: #fff;
  transform: scale(1.1);
}
.button-active {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  padding: 0;
  min-height: 40px;
  min-width: 275px;
  font-size: 1.3em;
  font-weight: 700;
  transition: 0.3s;
  animation: glowing 2s linear infinite;
}
@keyframes glowing {
  0% {
    box-shadow: 0 0px 30px #0c83e9;
  }
  50% {
    box-shadow: 0 0px 0px #0c83e9;
  }
  100% {
    box-shadow: 0 0px 30px #0c83e9;
  }
}
.btn-info:hover {
  color: #fff;
  background-color: #0c83e9;
  border-color: #0c83e9;
  box-shadow: 0 0px 20px #0c83e9;
}
.min-width-160 {
  min-width: 160px;
}
.form-group {
  legend {
    color: #fff;
  }
  .checkbox-group {
    min-height: 28px;
    display: flex;
    align-items: center;
  }
  .checkbox-group .custom-control-label {
    color: #fff;
  }
  .checkbox-group input[type='checkbox'] {
    width: 28px;
    height: 28px;
  }
  .checkbox-group .custom-control-label::after,
  .checkbox-group .custom-control-label::before {
    border-radius: 0;
    width: 28px;
    height: 28px;
    left: -2.8rem;
    top: 0;
  }
  .checkbox-group.ml-lg {
    padding-left: 2.8rem !important;
  }
  .d-flex {
    .checkbox-group {
      width: 38px;
      height: 38px;
      display: block;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      // -ms-transform: scale(1.2); /* IE */
      // -moz-transform: scale(1.2); /* FF */
      // -webkit-transform: scale(1.2); /* Safari and Chrome */
      // -o-transform: scale(1.2); /* Opera */
      // transform: scale(1.2);
      margin-right: 20px;
    }
  }
  .form-control {
    color: #444;
  }
  .multiselect__tags {
    min-height: 80px;
  }
  .multiselect__tag {
    background: #0c83e9;
    .multiselect__tag-icon:after {
      color: #000;
    }
  }
}
.box-setting-bot {
  position: relative;
  border: 1px #fff solid;
  padding: 20px;
  border-radius: 15px;
  .box-header {
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
    h3 {
      color: rgb(242, 234, 14);
      font-size: 22px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .box-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    button {
      width: 48%;
      background-color: #f2ea0e;
      color: #f00;
      font-weight: bold;
      letter-spacing: 2px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &.button-tab-active {
        background-color: #f2ea0e;
        color: #f00;
        opacity: 1;
      }
    }
  }
}
.button-group {
  .btn {
    margin: 0 5px;
    min-width: 160px;
    width: auto;
  }
  .btn-info {
    color: #fff;
    background-color: #0c83e9;
    border-color: #0c83e9;
  }
}
.Referral-card {
  .ref-id {
    h4 {
      margin-bottom: 8px;
      text-transform: capitalize;
      letter-spacing: 0.02em;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
      span {
        font-size: 22px;
        color: #2f394e;
        font-weight: 600;
      }
    }
  }
  .boxref {
    width: 100%;
    span {
      font-size: 17px;
      color: #6c757d;
      font-weight: 600;
    }
    .ref-link {
      margin-top: 5px;
      background: #009750;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 40px;
          padding: 0;
          background: #8dc63f;
          border: 0;
          outline: none;
          border-radius: 5px 0 0 5px;
          box-shadow: 0px 0px 5px 1px #00000043;
          font-weight: 550;
        }
      }
      input {
        height: 40px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 500;
        border-radius: 0px 5px 5px 0px;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
.balance-card,
.pnl-card {
  .box-info-content {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    .box-right,
    .box-left {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .cash {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2f394e !important;
        font-weight: 600;
        font-size: clamp(19px, 2vw, 20px);
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c757d;
        margin-left: 5px;
        font-weight: 600;
        font-size: clamp(0.7rem, 2vw, 0.8rem);
        text-transform: capitalize;
      }
    }
    .box-left {
    }
    .box-right {
    }
  }
}
</style>
