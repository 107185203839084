<script>
import VueTradingView from 'vue-trading-view';

export default {
  components: {
    VueTradingView,
  },
  data() {
    return {
      options: {
        autosize: true,
        symbol: 'BINANCE:BTCUSDT',
        interval: '30',
        timezone: 'Europe/London',
        theme: 'dark',
        style: '1',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        withdateranges: true,
        hide_side_toolbar: false,
        allow_symbol_change: true,
        save_image: false,
        details: true,
      },
    };
  },
};
</script>
<template>
  <b-container fluid class='p-0 mt-2'>
    <VueTradingView  class="chart-trading" :options="options" />
  </b-container>
</template>
<style lang="scss">
.chart-trading{
    height: 500px;
    border-radius: 5px;
}
</style>
